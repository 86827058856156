
import { useI18n } from "vue-i18n";
import { onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";

export default {
  name: "VarsSelect",
  props: {
    var_id: {
      type: [Number, Array],
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
  },
  emits: ["update:var_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const element_id = ref("");
    const tags = ref([
      "@code",
      "@type.name",
      "@type.code",
      "@office.name",
      "@office.is_main",
      "@office.company.name",
      "@office.company.cif",
      "@office.company.website",
      "@port.name",
      "@port.location.name",
      "@port.location.country.name",
      "@vessel.name",
      "@vessel.lloyds_iso",
      "@vessel.fretwork",
      "@vessel.capacity",
      "@vessel.length",
      "@vessel.beam",
      "@ship_consignee.name",
      "@ship_consignee.legal_name",
      "@ship_consignee.identification",
      "@ship_consignee.email",
      "@customer.name",
      "@customer.legal_name",
      "@customer.identification",
      "@customer.email",
      "@goods_consignees.type",
    ]);
    const inputVars = ref({
      loading: false,
      options: tags.value,
      list: tags.value,
    });

    const selectVars = (query) => {
      if (query !== "") {
        inputVars.value.loading = true;
        setTimeout(() => {
          inputVars.value.loading = false;
          inputVars.value.options = inputVars.value.list.filter(
            (item: string) => {
              return item.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        inputVars.value.options = inputVars.value.list;
      }
    };

    watch(
      () => props.var_id,
      (first) => {
        element_id.value = first;
      }
    );

    onMounted(() => {
      setTimeout(function () {
        element_id.value = props.var_id;
      }, 500);
    });

    return {
      element_id,
      inputVars,
      selectVars,
    };
  },
};
