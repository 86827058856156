
import { onMounted, onUpdated, ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import VarsSelect from "@/components/catalogs-select/VarsSelect.vue";

interface FormCatalog {
  id?: number;
  name: string;
}

export default {
  name: "TemplatesForm",
  components: {
    VarsSelect,
    Form,
    Field,
    ErrorMessage,
  },
  props: {
    entityId: String,
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const submitButton = ref<HTMLElement | null>(null);
    const addElement = ref(false);
    const isLoading = ref(false);
    const tableData = ref<[] | any>([]);
    const vars = ref([] as any);
    const form = ref<FormCatalog>({
      id: undefined,
      name: "",
    });

    const isNameRequired = (value) => {
      if (!value) {
        return t("rname");
      }
      return true;
    };

    onUpdated(() => {
      console.clear();
    });

    onMounted(() => {
      console.clear();
      if (props.entityId) {
        ApiService.get("/api/templates/view/" + props.entityId).then(
          ({ data }) => {
            form.value.id = data.id;
            form.value.name = data.name;
            data.vars.forEach((x) => vars.value.push(x.name));
          }
        );
      }
    });

    const createElement = async (payload, resetForm) => {
      try {
        const resp = (await ApiService.post("/api/templates", payload)).data;
        emit("success", resp);
        resetForm();
        submitButton.value?.removeAttribute("data-kt-indicator");
      } catch (error) {
        submitButton.value?.removeAttribute("data-kt-indicator");
      }
    };

    const updateElement = (payload, resetForm) => {
      ApiService.put("/api/templates/view/" + props.entityId, payload)
        .then(({ data }) => {
          emit("success", data);
          submitButton.value?.removeAttribute("data-kt-indicator");
          form.value = data;
          resetForm();
        })
        .catch(() => {
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const onSumbit = (value, { resetForm }) => {
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
        const payload = {
          id: form.value.id,
          name: form.value.name,
          vars: vars.value,
        };
        if (form.value.id) {
          updateElement(payload, resetForm);
        } else {
          createElement(payload, resetForm);
        }
      }
    };

    return {
      submitButton,
      form,
      addElement,
      isLoading,
      tableData,
      vars,
      onSumbit,
      isNameRequired,
      updateElement,
      createElement,
    };
  },
};
